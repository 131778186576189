@use '@angular/material' as mat;
@import "../../node_modules/@fortawesome/fontawesome-pro/css/all.css";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import 'variables';
@import '@angular/material/theming';
@import 'nav';
@import "article";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
$bv-brand: (
    50: #FFFFFF,
    100: lighten($main_color, 40),
    200: lighten($main_color, 30),
    300: lighten($main_color, 15),
    400: $main_color,
    500: $main_color,
    600: $main_color,
    700: $main_color,
    800: darken($main_color, 5),
    900: darken($main_color, 10),
    A100: #FFFFFF,
    A200: $main_color,
    A400: $main_color,
    A700: $main_color,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);
$bv-brand-accent: (
    50: #FFFFFF,
    100: lighten($sec_color, 40),
    200: lighten($sec_color, 30),
    300: lighten($sec_color, 15),
    400: $sec_color,
    500: lighten($sec_color, 10),
    600: lighten($sec_color, 5),
    700: $sec_color,
    800: darken($sec_color, 5),
    900: darken($sec_color, 10),
    A100: $sec_color,
    A200: $sec_color,
    A400: $sec_color,
    A700: $sec_color,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);

$red-palette: (
    50: #FFEBEE,
    100: #FFCDD2,
    200: #EF9A9A,
    300: #E57373,
    400: #EF5350,
    500: #F44336,
    600: #E53935,
    700: #D32F2F,
    800: #C62828,
    900: #B71C1C,
    A100: #FF8A80,
    A200: #FF5252,
    A400: #FF1744,
    A700: #D50000,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $dark-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$candy-app-primary: mat.define-palette($bv-brand);
$candy-app-accent: mat.define-palette($bv-brand-accent, A400, A200, A700);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.define-palette($red-palette, A200);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($candy-app-theme);
@import "icons.scss";

body {
    @media print {
        > div {
            display: none;
        }
    }

    color: $main_color;
    background: none;
    padding-bottom: 72px;
    padding-top: calc(80px + env(safe-area-inset-top));

    @media(max-width: 767px) {
        padding-top: calc(68px + env(safe-area-inset-top));
    }
}

input:-webkit-autofill {
    background-color: transparent;
}

textarea.mat-input-element {
    overflow: hidden !important;
}

a {
    &.mat-button, &.mat-raised-button {
        text-decoration: none;
    }
}

.btn-primary {
    background-color: $main_color !important;

    &:focus, &:active, &:hover {
        background-color: $main_color !important;
    }
}

.btn-outline-primary {
    color: $main_color !important;
    border-color: $main_color !important;

    &:focus, &:active, &:hover {
        border-color: $main_color !important;
        color: $main_color !important;
    }
}

.btn-outline-primary:not([disabled]):not(.disabled):active,
.btn-outline-primary:not([disabled]):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    background-color: $main_color !important;
    box-shadow: 0 0 0 0.2rem rgba(15, 0, 100, 0.5);
    color: #FFFFFF !important;
}

.btn {
    text-transform: none;
    border-radius: 4px !important;
    font-size: 14px;
    font-weight: normal;

    &:disabled {
        cursor: not-allowed !important;
    }
}

select {
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
    border-color: $main_color !important;
    background: url('images/icons/angle-down.svg') no-repeat right !important;
    -webkit-appearance: none !important;
    background-position: right 10px center !important;
    background-size: 12px 12px !important;
    padding-left: 10px !important;
    font-size: 14px !important;
    padding-top: 14px !important;
    padding-bottom: 0px !important;
    position: relative;

    &.selected {
        padding-top: 1px !important;
    }

    &.distance-icon {
        background-image: url('images/icons/geo.svg') !important;
    }

    ~ label {
        position: absolute;
        top: 7px;
        left: 25px;
        color: $main_color;
        font-size: 10px;
    }
}

//the checkbox background
.custom-control-indicator {
    background-color: white;
    border: 1px solid $main_color;
}

.custom-control-input:checked ~ .custom-control-indicator {
    background-color: $main_color;
}

.pointer {
    cursor: pointer !important;
}

.logo-small {
    background-image: url("images/logo-small.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 48px;
    height: 41px;
}

.header-container {
    user-select: none;
    position: fixed;
    width: 100vw;
    min-height: 125px;
    top: 0;
    padding-top: env(safe-area-inset-top);
    z-index: 1000;
    background-color: #FFFFFF;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media(max-width: 767px) {
        min-height: 100px;
    }
}

.content-container {
    height: calc(100% - 125px);
    height: calc(100% - 125px - env(safe-area-inset-top, 0));
    position: fixed;
    width: 100vw;
    overflow: auto;
    bottom: 0;
    -webkit-overflow-scrolling: touch;
    @media(max-width: 767px) {
        height: calc(100% - 100px);
        height: calc(100% - 100px - env(safe-area-inset-top, 0));
    }

}

a {
    color: $main_color;

    &:hover {
        color: #67C6DD;
    }
}

.txtbold {
    font-weight: bold;
}


.hidden-sm-down {
    @media (max-width: 768px) {
        display: none !important;
    }
}

.txt-ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.md-form label {
    pointer-events: none;
}

.footer {
    z-index: 10;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: right;
    color: #FFFFFF;
    padding: 15px 0;
    background-color: #0F0064;
    font-size: 20px;
    padding-bottom: env(safe-area-inset-bottom, 15px);

    img {
        margin-top: -3px;
        height: 23px;
    }

    .txtleft {
        text-align: left;
    }

    a {
        color: #FFFFFF;

        &:hover {
            color: #DDDDDD;
        }
    }

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media(max-width: 767px) {
        font-size: 14px;
        text-align: center;
        .txtleft {
            text-align: center;
        }
        padding: 10px 0;
        img {
            height: 18px;
        }
    }
}

.modal-dialog {
    margin-top: 55px;
}


.bs-datepicker-body {
    .days {
        tr {
            th:first-of-type, td:first-of-type {
                display: none;
            }

            th:last-of-type, td:last-of-type {
                display: none;
            }
        }
    }
}

timepicker {
    .btn {
        width: 100%;
        margin: 0px;
        padding: 8px !important;
        background-color: #0F0064;
        border: 1px solid #0F0064;
        color: #FFFFFF !important;

        &:hover {
            border: 1px solid #0F0064;
            color: #0F0064 !important;
        }

        span {
            margin: 0px auto;
        }
    }

    .form-group ~ td {
        font-weight: bold;
        font-size: 16px;
    }
}

.user-select-none {
    user-select: none;
}

.curpoint {
    cursor: pointer;
}

.spacer {
    flex: 1 1 auto;
}

.mat-input-element:disabled {
    color: #0F0064;
}

.image-viewer-dialog {
    max-width: 100vw !important;
    max-height: 100vh !important;
    width: 100%;
    @media screen and (max-width: 500px) {
        max-height: 100% !important;
        max-width: 100vw !important;
    }

    .mat-dialog-container {
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        overflow: visible;
    }
}

.confirm-dialog, .document-dialog {
    max-width: 90vw !important;

    .mat-dialog-container {
        position: relative !important;
        padding: 0;
        width: 500px;
        height: auto;
        max-height: 95vh;

        .dialog-container {
            display: flex;
            flex-direction: column;
            height: auto;
            max-height: 500px;
            padding: 1.5rem;

            .dialog-close {
                position: absolute;
                top: 1rem;
                right: 1rem;
            }

            .dialog-title {
                display: flex;
                flex: 0 0 auto;
                font-size: 1.5rem;
                margin-bottom: 1.5rem
            }

            .dialog-content {
                word-break: break-word;
                display: flex;
                flex: 1 1 auto;
                position: relative;
                overflow-y: auto;

                table {
                    margin-bottom: 0;
                }

                p {
                    margin-bottom: 2rem;
                }
            }

            @media screen and (min-width: 576px) {
                padding: 2rem;
                .dialog-close {
                    top: 1.5rem;
                    right: 1.5rem;
                }
            }
        }
    }
}

button {
    outline: none !important;
}

.underline {
    text-decoration: underline;
}

.mat-stroked-button:not(.mat-button-disabled) {
    border-color: rgba(#0F0064, .3);
}

.mainheader {
    img {
        margin: 20px 20px 20px 0;
        width: 110px;
    }

    > div {
        max-width: 1500px;
    }

    .row {
        height: 65px;
    }

    position: fixed;
    width: 100%;
    top: env(safe-area-inset-top);
    height: 65px;
    z-index: 2;
    background-color: white;

    .menu {
        a {
            margin-right: 1rem;

            &.active {
                font-weight: bold;
            }
        }
    }

    .text {
        font-family: Roboto;
        font-size: 24px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #0F0064;
    }
}

.projects-container {
    max-width: 1500px;
    @media (max-width: 1140px) {
        padding: 0;
    }
}

.mainbalk {
    height: 15px;
    background: $main-color;
    position: fixed;
    width: 100%;
    top: calc(65px + env(safe-area-inset-top));
    display: flex;
    z-index: 1;

    .menu {
        align-items: flex-end;
    }

    a {
        &.active {
            color: #FFFFFF;
        }

        color: rgba(255, 255, 255, 0.7);
        margin-right: 1rem;
    }

    @media(max-width: 767px) {
        height: 3px;
    }
}

.cursor-pointer {
    cursor: pointer;
}


.projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    justify-content: center;

    @media(min-width: 768px) {
        &::after {
            content: "";

        }
    }

    .project {
        width: 270px;
        padding: 30px;
        margin: 15px;
        @media(max-width: 767px) {
            padding: .5rem 1rem;
        }
        border: solid 1px rgba(15, 0, 100, 0.15);
        border-left: 6px solid $main_color;
        font-size: 12px;

        .name {
            position: relative;
            font-size: 24px;
            font-weight: bold;

            .fa {
                opacity: 0;
                transition: 150ms;
                position: absolute;
                top: -17px;
                right: -19px;
                font-size: 18px;
            }
        }

        @media(min-width: 769px) {
            @supports (-webkit-touch-callout: none) {
                &:hover {
                    .name .fa {
                        opacity: 1;
                    }
                }
            }
        }
        @supports not (-webkit-touch-callout: none) {
            .name .fa {
                opacity: 1;
            }
        }

        .manager {
            font-weight: bold;
        }

        .budget {
            font-size: 18px;
            font-weight: 300;
        }

        .divider {
            height: 1px;
            background: rgba(15, 0, 100, 0.15);
            margin: 15px 0;
        }

        .tomap {
            display: block;
            font-weight: bold;
            padding: 20px 0;
            text-align: center;
            font-size: 13px;
            margin: 0 -10px;
        }

        .button {
            max-width: 150px;
        }

    }
}

.button, a.button {
    border: 0;
    display: block;
    padding: 12px;
    font-size: 15px;
    background: $main_color;
    color: #FFFFFF;
    text-align: center;
    margin: 0 auto;
    max-width: 230px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: #FFFFFF;
    }
}


.forward {
    font-size: 13px;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
    transition: 150ms;

    img {
        transition: 300ms;
        margin-top: 2px;
    }

    &:hover {
        color: $main-color;

        img {
            transform: translate(5px, 0px);
        }
    }
}

.ca-logo {
    height: 74px;
    max-width: 200px;
    min-width: 120px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right center;
}

.cursor-default {
    cursor: default;
}

.mat-checkbox label {
    margin-bottom: 0;
}

.h-100 {
    height: 100%;
}


body.screenshot .router-outlet {
    height: auto !important;
}

